





















































import {Component, Vue} from 'vue-property-decorator'
import boodkaFmDataService from "@/service/boodka-fm.data.service";

@Component({})
export default class StatisticsSection extends Vue {
  extraInfo = {};

  fetchExtraData(): void {
    this.extraInfo = boodkaFmDataService.getExtraInfo();
  }

  mounted() {
    this.fetchExtraData();
  }
}
