import {BOODKA_FM_ALERTS_SPREADSHEET_ID} from "@/constants/google-spreadsheet-constant";
import {GoogleSpreadsheet} from 'google-spreadsheet';
const secretKey = require('./secrect/boodkafm-client.json');

class GoogleDriveService {
    public async readData(): Promise<GoogleSpreadsheet> {
        return new Promise((resolve) => {
            const doc = new GoogleSpreadsheet(BOODKA_FM_ALERTS_SPREADSHEET_ID);
            doc.useServiceAccountAuth(secretKey);
            resolve(doc)
        })

    }
}


export default new GoogleDriveService();
