export interface Song {
    artist: string;
    title: string;
    entries: SongEntry[];
}

export interface SongEntry {
    date?: string;
    video_id?: string;
    start_time?: number;
    end_time?: number;
    hidden?: boolean;
}

export interface ExtraInfo {
    total_streams: number;
    total_songs: number;
    total_unique_songs: number;
    total_artists: number;
}

export interface Stream {
    date: string;
    videoId: string;
}

export class LiveStream implements Stream {
    public date: string;
    public videoId: string;

    constructor(date: string, videoId: string) {
        this.date = date;
        this.videoId = videoId;
    }
}

export interface SongBooking {
    active?: boolean;
    id: number;
    artist: string;
    title: string;
    singers?: SingerType[];
}

export enum SingerType {
    JULIA = 'JULIA',
    VICTOR = 'VICTOR',
    IGOR = 'IGOR'
}
