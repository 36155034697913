

























































import {Component, Vue, Watch} from 'vue-property-decorator'
import {Song, SongEntry} from "@/model/song";
import boodkaFmDataService from "@/service/boodka-fm.data.service";
import SongDetails from "@/components/SongDetails.vue";
import StreamsSummary from "@/components/StreamsSummary.vue";
import {Base64} from 'js-base64';
import {FOOTER_PROPS_DEFAULT} from "@/constants/footer-props";

@Component({
  components: {StreamsSummary, SongDetails}
})
export default class Songs extends Vue {
  footerProps = FOOTER_PROPS_DEFAULT;
  search = '';
  expanded = [];
  selectedSong: Song | {} = {};
  songArtist = '';
  songTitle = '';

  headers = [
    {text: 'Artist', value: 'artist',},
    {text: 'Title', value: 'title'},
    {text: 'Entries', value: 'entries'},
  ]
  songs: Song[] = [];

  @Watch('search')
  searchWatch(): void {
    if (this.songArtist || this.songTitle) {
      this.loadSongs();
    }
  }


  loadSongs(artist?: string, title?: string): void {
    this.songs = boodkaFmDataService.getSongs();
    let copy: Song[] = Object.assign([], this.songs);
    if (artist) {
      copy = copy.filter(s => s.artist === artist);
    }
    if (title) {
      copy = copy.filter(s => s.title === title);
    }
    this.songs = copy;
  }


  getColor(entries: SongEntry[]): string {
    const count = entries.length | 0
    if (count > 5) return 'red'
    else if (count > 3) return 'orange'
    else return 'green'
  }

  handleClick(item: Song) {
    this.selectedSong = item;
    this.$vuetify.goTo(0);
  }

  mounted() {
    if (this.$route.params.songArtist && this.$route.params.songTitle) {
      this.songArtist = Base64.fromBase64(this.$route.params.songArtist);
      this.songTitle = Base64.fromBase64(this.$route.params.songTitle);
    }
    this.loadSongs(this.songArtist, this.songTitle);
  }
}
