












































import {INSTAGRAM_CHANNEL_URL, TWITCH_CHANNEL_URL, YOUTUBE_CHANNEL_URL} from "@/constants/boodka-fm.constant";
import {Component, Vue} from 'vue-property-decorator';

export type FooterLinks = { icon: string; url: string }

@Component({})
export default class Footer extends Vue {
  links: FooterLinks[] = [
    {
      icon: 'mdi-youtube',
      url: YOUTUBE_CHANNEL_URL
    },
    {
      icon: 'mdi-twitch',
      url: TWITCH_CHANNEL_URL
    },
    {
      icon: 'mdi-instagram',
      url: INSTAGRAM_CHANNEL_URL
    }
  ]

}
