/* tslint:disable */
/* eslint-disable */
/**
 * boodkafm-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Audio } from '../model';
/**
 * BookingSongAudioApi - axios parameter creator
 * @export
 */
export const BookingSongAudioApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add audio to song object
         * @param {number} id 
         * @param {Audio} [audio] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAudio: async (id: number, audio?: Audio, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createAudio.');
            }
            const localVarPath = `/song/{id}/audio`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof audio !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(audio !== undefined ? audio : {}) : (audio || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary add audio list
         * @param {Array<Audio>} [audio] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAudios: async (audio?: Array<Audio>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/audio`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof audio !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(audio !== undefined ? audio : {}) : (audio || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete audio object by id
         * @param {number} id 
         * @param {number} audioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAudioById: async (id: number, audioId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteAudioById.');
            }
            // verify required parameter 'audioId' is not null or undefined
            if (audioId === null || audioId === undefined) {
                throw new RequiredError('audioId','Required parameter audioId was null or undefined when calling deleteAudioById.');
            }
            const localVarPath = `/song/{id}/audio/{audioId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"audioId"}}`, encodeURIComponent(String(audioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get audio object by id
         * @param {number} id 
         * @param {number} audioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAudioById: async (id: number, audioId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAudioById.');
            }
            // verify required parameter 'audioId' is not null or undefined
            if (audioId === null || audioId === undefined) {
                throw new RequiredError('audioId','Required parameter audioId was null or undefined when calling getAudioById.');
            }
            const localVarPath = `/song/{id}/audio/{audioId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"audioId"}}`, encodeURIComponent(String(audioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get list of not mapped audios
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotMappedAudios: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/audio/not-mapped`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update audio object
         * @param {number} id 
         * @param {number} audioId 
         * @param {Audio} [audio] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAudio: async (id: number, audioId: number, audio?: Audio, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateAudio.');
            }
            // verify required parameter 'audioId' is not null or undefined
            if (audioId === null || audioId === undefined) {
                throw new RequiredError('audioId','Required parameter audioId was null or undefined when calling updateAudio.');
            }
            const localVarPath = `/song/{id}/audio/{audioId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"audioId"}}`, encodeURIComponent(String(audioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof audio !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(audio !== undefined ? audio : {}) : (audio || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingSongAudioApi - functional programming interface
 * @export
 */
export const BookingSongAudioApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add audio to song object
         * @param {number} id 
         * @param {Audio} [audio] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAudio(id: number, audio?: Audio, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Audio>> {
            const localVarAxiosArgs = await BookingSongAudioApiAxiosParamCreator(configuration).createAudio(id, audio, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary add audio list
         * @param {Array<Audio>} [audio] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAudios(audio?: Array<Audio>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Audio>>> {
            const localVarAxiosArgs = await BookingSongAudioApiAxiosParamCreator(configuration).createAudios(audio, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete audio object by id
         * @param {number} id 
         * @param {number} audioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAudioById(id: number, audioId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BookingSongAudioApiAxiosParamCreator(configuration).deleteAudioById(id, audioId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get audio object by id
         * @param {number} id 
         * @param {number} audioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAudioById(id: number, audioId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Audio>> {
            const localVarAxiosArgs = await BookingSongAudioApiAxiosParamCreator(configuration).getAudioById(id, audioId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get list of not mapped audios
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotMappedAudios(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Audio>>> {
            const localVarAxiosArgs = await BookingSongAudioApiAxiosParamCreator(configuration).getNotMappedAudios(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update audio object
         * @param {number} id 
         * @param {number} audioId 
         * @param {Audio} [audio] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAudio(id: number, audioId: number, audio?: Audio, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Audio>> {
            const localVarAxiosArgs = await BookingSongAudioApiAxiosParamCreator(configuration).updateAudio(id, audioId, audio, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BookingSongAudioApi - factory interface
 * @export
 */
export const BookingSongAudioApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary add audio to song object
         * @param {number} id 
         * @param {Audio} [audio] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAudio(id: number, audio?: Audio, options?: any): AxiosPromise<Audio> {
            return BookingSongAudioApiFp(configuration).createAudio(id, audio, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary add audio list
         * @param {Array<Audio>} [audio] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAudios(audio?: Array<Audio>, options?: any): AxiosPromise<Array<Audio>> {
            return BookingSongAudioApiFp(configuration).createAudios(audio, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete audio object by id
         * @param {number} id 
         * @param {number} audioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAudioById(id: number, audioId: number, options?: any): AxiosPromise<void> {
            return BookingSongAudioApiFp(configuration).deleteAudioById(id, audioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get audio object by id
         * @param {number} id 
         * @param {number} audioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAudioById(id: number, audioId: number, options?: any): AxiosPromise<Audio> {
            return BookingSongAudioApiFp(configuration).getAudioById(id, audioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get list of not mapped audios
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotMappedAudios(options?: any): AxiosPromise<Array<Audio>> {
            return BookingSongAudioApiFp(configuration).getNotMappedAudios(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update audio object
         * @param {number} id 
         * @param {number} audioId 
         * @param {Audio} [audio] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAudio(id: number, audioId: number, audio?: Audio, options?: any): AxiosPromise<Audio> {
            return BookingSongAudioApiFp(configuration).updateAudio(id, audioId, audio, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookingSongAudioApi - object-oriented interface
 * @export
 * @class BookingSongAudioApi
 * @extends {BaseAPI}
 */
export class BookingSongAudioApi extends BaseAPI {
    /**
     * 
     * @summary add audio to song object
     * @param {number} id 
     * @param {Audio} [audio] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingSongAudioApi
     */
    public createAudio(id: number, audio?: Audio, options?: any) {
        return BookingSongAudioApiFp(this.configuration).createAudio(id, audio, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary add audio list
     * @param {Array<Audio>} [audio] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingSongAudioApi
     */
    public createAudios(audio?: Array<Audio>, options?: any) {
        return BookingSongAudioApiFp(this.configuration).createAudios(audio, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete audio object by id
     * @param {number} id 
     * @param {number} audioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingSongAudioApi
     */
    public deleteAudioById(id: number, audioId: number, options?: any) {
        return BookingSongAudioApiFp(this.configuration).deleteAudioById(id, audioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get audio object by id
     * @param {number} id 
     * @param {number} audioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingSongAudioApi
     */
    public getAudioById(id: number, audioId: number, options?: any) {
        return BookingSongAudioApiFp(this.configuration).getAudioById(id, audioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get list of not mapped audios
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingSongAudioApi
     */
    public getNotMappedAudios(options?: any) {
        return BookingSongAudioApiFp(this.configuration).getNotMappedAudios(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update audio object
     * @param {number} id 
     * @param {number} audioId 
     * @param {Audio} [audio] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingSongAudioApi
     */
    public updateAudio(id: number, audioId: number, audio?: Audio, options?: any) {
        return BookingSongAudioApiFp(this.configuration).updateAudio(id, audioId, audio, options).then((request) => request(this.axios, this.basePath));
    }
}
