import {bookingSongApi, bookingSongAudioApi} from "@/api/api.instance";
import {AxiosPromise} from "axios";
import {Audio, BookingSong} from "@/api";

class BookingSongAudioService {
    getAudioById(id: number, audioId: number): AxiosPromise<Audio> {
        return bookingSongAudioApi.getAudioById(id, audioId);
    }

    createAudio(id: number, payload: Audio): AxiosPromise<BookingSong> {
        return bookingSongAudioApi.createAudio(id, payload);
    }

    getNotMappedAudios(): AxiosPromise<Audio[]> {
        return bookingSongAudioApi.getNotMappedAudios();
    }

    deleteAudioById(id: number, audioId: number): AxiosPromise<void> {
        return bookingSongAudioApi.deleteAudioById(id, audioId);
    }

    updateAudio(id: number, audioId: number, payload: Audio): AxiosPromise<BookingSong> {
        return bookingSongAudioApi.updateAudio(id, audioId, payload);
    }
}

export default new BookingSongAudioService();
