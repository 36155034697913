import {accountApi} from '@/api/api.instance'
import {User} from "@/api";
import {AxiosPromise} from "axios";

class AccountService {
    getAccount(): AxiosPromise<User> {
        return accountApi.getAccount();
    }
}

export default new AccountService();
