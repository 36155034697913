import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);
Vue.extend().component('vue-sound',)

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        dark: true, themes: {
            dark: {
                primary: 'white',
                background: '#272727'
            }
        }
    },
});
