var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-col',{attrs:{"cols":"12","md":"8","order":"2"}},[_c('div',{staticClass:"my-2"},[_c('span',{staticClass:"display-3 section-header font-weight-bold deep-orange--text"},[_vm._v("boodkaFm")]),_c('br'),_c('span',{staticClass:"my-2 text-h3 font-weight-light"},[_vm._v("Список песен")]),_c('br')])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8","order":"2"}},[_c('div',[_c('v-alert',{attrs:{"icon":"mdi-shield-alert","prominent":"","text":"","color":"deep-orange"}},[_vm._v(" Заказывая песню из списка не забудь указать номер песни в сообщении ")])],1)])],1),_c('v-row'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8","order":"2"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex flex-column w-100"},[_c('v-combobox',{staticClass:"d-flex",attrs:{"items":_vm.items,"chips":"","color":"white","clearable":"","label":"Исполнитель","multiple":"","prepend-icon":"mdi-account","solo":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":"","color":"deep-orange","light":""},on:{"click":select,"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item.name))])])]}},{key:"item",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.name))])]}}]),model:{value:(_vm.chips),callback:function ($$v) {_vm.chips=$$v},expression:"chips"}}),_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":"Поиск...","single-line":"","hide-details":"","dark":"","color":"deep-orange","autofocus":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('v-data-table',{staticClass:"elevation-1 pointer",attrs:{"footer-props":_vm.footerProps,"headers":_vm.headers,"items":_vm.songs,"search":_vm.search,"items-per-page":50,"sort-by":['id'],"sort-asc":[true],"mobile-breakpoint":"0"},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.singers",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getSingerName(item.singers)))])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"deep-orange","light":""}},[_c('strong',[_vm._v(_vm._s(item.id))])])]}}])}),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"1000"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[[_c('v-card',[_c('v-toolbar',{attrs:{"color":"deep-orange","dark":""}},[_c('v-chip',{staticClass:"elevation-10",attrs:{"color":"deep-orange","light":""}},[_c('strong',[_vm._v("#"+_vm._s(_vm.selectedSongBooking.id))])]),_c('span',{staticClass:"ml-3 font-weight-bold dialog-title"},[_vm._v(" "+_vm._s(_vm.selectedSongBooking.artist)+" - "+_vm._s(_vm.selectedSongBooking.title))])],1),_c('v-card-text',[_c('v-alert',{attrs:{"icon":"mdi-shield-alert","prominent":"","text":"","color":"deep-orange"}},[_vm._v(" Заказывая песню из списка не забудь указать номер песни в сообщении ")])],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"deep-orange","light":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("Close ")])],1)],1)]],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }