






import {Component, Vue} from 'vue-property-decorator';
import Songs from "@/components/Songs.vue";

@Component({
  components: {Songs}
})
export default class SongsCatalogue extends Vue {

}
