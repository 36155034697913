import {YoutubeVideoMeta} from "@/model/youtube-video-meta";

class BoodkaFmYoutubeService {

    youtube = require('youtube-metadata-from-url');
    url = 'https://youtu.be/';

    public getYoutubeVideo(id: string): Promise<YoutubeVideoMeta> {
        return this.youtube.metadata(this.url + id);
    }
}

export default new BoodkaFmYoutubeService();
