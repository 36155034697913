import {ExtraInfo, LiveStream, Song, SongBooking, SongEntry} from "@/model/song";
import * as BOODKA_FM_DATA from '../data/songs.json';
import * as BOOKING_DATA from '../data/bookings.json';
import moment from "moment";
import {Observable, of} from "rxjs";

class BoodkaFmDataService {
    public getSongs(): Song[] {
        return BoodkaFmDataService.readSongsFromFile(BOODKA_FM_DATA);
    }

    public getSongBookings(): SongBooking[] {
        return BoodkaFmDataService.readBookingsFromFile(BOOKING_DATA);
    }

    public getExtraInfo() {
        return BoodkaFmDataService.readExtraInfoFromFile(BOODKA_FM_DATA);
    }

    public getStreams(last?: number): LiveStream[] {
        const songs: Song[] = this.getSongs();

        const liveStreamsMap: Map<string, LiveStream> = new Map();

        songs.forEach(s => s.entries.forEach(entry => {
            liveStreamsMap.set(entry.video_id!, new LiveStream(entry!.date!, entry.video_id!));
        }))

        const streams: LiveStream[] = Array.from(liveStreamsMap.values());

        if (last) {
            return this.getLastStreamsByNumber(last, streams);
        }
        return streams;
    }

    private getLastStreamsByNumber(n: number, streams: LiveStream[]): LiveStream[] {
        const copy: LiveStream[] = Object.assign([], streams.filter(s => s.date.length && s.date.length >= 10));
        return copy.sort((a, b) => moment(b.date).diff(moment(a.date))).slice(0, n)
    }

    public getRandomSongEntry(): SongEntry {
        const songEntries: SongEntry[] = this.getSongsEntries();

        return songEntries[this.getRandom(songEntries.length)];

    }

    public getSongsEntries(): SongEntry[] {
        const songs: Song[] = this.getSongs();

        const entries: SongEntry[] = [];
        songs.forEach(s => s.entries.forEach(e => entries.push(e)));

        return entries;
    }

    public searchSongs(term?: string): Observable<Song[]> {
        if (!term) return of(this.getSongs());

        return of(this.getSongs()
            .filter(song => song.title.toLowerCase().includes(term.toLowerCase())
                || song.artist.toLowerCase().includes(term.toLowerCase())));

    }

    // eslint-disable-next-line
    private static readSongsFromFile(file: any): Song[] {
        // eslint-disable-next-line
        const songs: Song[] = (file as any).default.songs;

        // some operations
        const notHiddenSongs = songs
            .map(song => {
                song.entries = song.entries.filter(entry => !entry.hidden);
                return song;
            })
            .filter(song => song.entries.length > 0);

        return notHiddenSongs;
    }

    // eslint-disable-next-line
    private static readBookingsFromFile(file: any): SongBooking[] {
        // eslint-disable-next-line
        const bookings: SongBooking[] = (file as any).default.bookings;

        // some operations

        return bookings.filter(s => s.active);
    }

    // eslint-disable-next-line
    private static readExtraInfoFromFile(file: any): ExtraInfo {
        // eslint-disable-next-line
        const extraInfo: ExtraInfo = (file as any).default.extra_info;

        // some operations

        return extraInfo;
    }

    private getRandom(max: number) {
        return Math.floor(Math.random() * Math.floor(max))
    }


}

export default new BoodkaFmDataService();
