


















































export const YOUTUBE_URL = 'https://www.youtube.com/watch?v='
import {Component, Prop, Vue} from 'vue-property-decorator'
import {YoutubeVideoMeta} from "@/model/youtube-video-meta";

@Component({})
export default class StreamCard extends Vue {
  @Prop() youtubeVideoMeta!: YoutubeVideoMeta;
  @Prop() tag!: string;
  show = false;

  getUrl(): string {
    return YOUTUBE_URL + this.tag;
  }
}
