





































































































































































































import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator'
import {SongBooking} from "@/model/song";
import {Audio, BookingSong} from "@/api";
import bookingsongService from "@/service/bookingsong.service";
import bookingsongaudioService from "@/service/bookingsongaudio.service";
import {quillEditor} from 'vue-quill-editor'

@Component({
  components: {quillEditor}
})
export default class DashboardBookingList extends Vue {
  @Prop()
  song: SongBooking | undefined;
  content = ''

  editorOption = {
    placeholder: 'Текст песни'
  }

  panel = [0]
  bookingSong: BookingSong = {};
  audio: Audio = {};
  valid = true;

  @Emit('close-dialog')
  dialogClose(): boolean {
    return true;
  }

  @Watch('song', {immediate: true})
  watchSong(val) {
    if (val?.id) {
      this.fetch(val.id);
    }
  }

  fetch(bookingId: string): void {
    bookingsongService.getSongByBookingId(bookingId).then(resp => {
      this.bookingSong = resp.data;
    }).catch(() => {
      this.bookingSong = this.createBookingSong(this.song!);
    })
  }

  createBookingSong(song: SongBooking): BookingSong {
    return {artist: song.artist, title: song.title, bookingId: song.id.toString()}
  }

  save() {
    if (!this.bookingSong?.id) {
      bookingsongService.createSong(this.bookingSong!).then((resp) => {
        this.bookingSong = resp.data;
      })
    } else {
      bookingsongService.updateSong(this.bookingSong.id!, this.bookingSong).then((resp) => {
        this.bookingSong = resp.data;
      })
    }
  }

  addAudio(): void {
    if (!this.bookingSong?.id) {
      bookingsongService.createSong(this.bookingSong!).then((resp) => {
        this.bookingSong = resp.data;
        this.addAudioToSong(this.bookingSong.id!);
      })
    } else {
      this.addAudioToSong(this.bookingSong?.id!);
    }
  }

  addAudioToSong(songId: number): void {
    let query
    if (!this.audio.id) {
      query = bookingsongaudioService.createAudio(songId, this.audio)
    } else {
      query = bookingsongaudioService.updateAudio(songId, this.audio.id, this.audio)
    }
    query.then(() => {
      this.fetch(this.bookingSong?.bookingId!.toString());
    }).finally(() => {
      this.audio = {}
    })
  }

  getGoogleDriveUrl(tag: string) {
    return "https://drive.google.com/file/d/"+tag+"/preview"
  }

  deleteAudio(audio: Audio): void {
    bookingsongaudioService.deleteAudioById(this.bookingSong?.id!, audio.id!)
        .then(() => {
          this.fetch(this.bookingSong?.bookingId!);
        }).finally(() => {
      this.audio = {}
    })
  }

  updateAudio(audio: Audio): void {
    this.audio = audio;
  }

  validate() {
    // TODO
  }

  reset() {
    this.audio = {};
  }
}
