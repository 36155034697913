



















































































































































import {Component, Vue, Watch} from 'vue-property-decorator'
import {SingerType, SongBooking} from "@/model/song";
import boodkaFmDataService from "@/service/boodka-fm.data.service";
import SongDetails from "@/components/SongDetails.vue";
import StreamsSummary from "@/components/StreamsSummary.vue";
import {FOOTER_PROPS_DEFAULT} from "@/constants/footer-props";

@Component({
  components: {StreamsSummary, SongDetails}
})
export default class Booking extends Vue {
  footerProps = FOOTER_PROPS_DEFAULT;
  donationUrl = 'https://www.donationalerts.com/r/boodkafm';
  search = '';
  expanded = [];
  selectedSongBooking: SongBooking | {} = {};
  songArtist = '';
  songTitle = '';
  chips = [{type: 'JULIA', name: 'Юлия'}];
  items = [
    {type: 'JULIA', name: 'Юлия'},
    {type: 'VICTOR', name: 'Виктор'},
    {type: 'IGOR', name: 'Игорь'}];

  headers = [
    {text: '#', value: 'id',},
    {text: 'Артист', value: 'artist'},
    {text: 'Название', value: 'title'},
    {text: 'Исполнитель', value: 'singers'},
  ]

  songs: SongBooking[] = [];
  dialog = false;

  @Watch('search')
  searchWatch(): void {
    if (this.songArtist || this.songTitle) {
      this.loadBookings();
    }
  }

  @Watch('chips')
  chipsWatch(): void {
    this.loadBookings();
  }

  loadBookings(artist?: string, title?: string): void {
    this.songs = boodkaFmDataService.getSongBookings();
    let copy: SongBooking[] = Object.assign([], this.songs);
    if (this.chips.length) {
      copy = copy.filter(s => s.singers?.some(a => this.chips.map(c => c.type).includes(a)));
    }
    if (artist) {
      copy = copy.filter(s => s.artist === artist);
    }
    if (title) {
      copy = copy.filter(s => s.title === title);
    }
    this.songs = copy;
  }


  handleClick(item: SongBooking): void {
    this.selectedSongBooking = item;
    this.$router.push(this.donationUrl);
  }

  closeDialog(): void {
    this.dialog = false;
    this.selectedSongBooking = {};
  }

  remove(item): void {
    this.chips.splice(this.chips.indexOf(item), 1)
    this.chips = [...this.chips]
  }

  getSingerName(types: SingerType[]): string {
    return types.map(t => this.items.find(i => i.type = t)).map(m => m!.name).join(',');
  }

  mounted() {
    this.loadBookings(this.songArtist, this.songTitle);
  }
}
