import {bookingSongApi} from "@/api/api.instance";
import {AxiosPromise} from "axios";
import {BookingSong} from "@/api";

class BookingSongService {
    getSongById(id: number): AxiosPromise<BookingSong> {
        return bookingSongApi.getSongById(id);
    }

    getSongByBookingId(bookingId: string): AxiosPromise<BookingSong> {
        return bookingSongApi.getSongByBookingId(bookingId);
    }

    createSong(payload: BookingSong): AxiosPromise<BookingSong> {
        return bookingSongApi.createSong(payload);
    }

    updateSong(songId: number, payload: BookingSong): AxiosPromise<BookingSong> {
        return bookingSongApi.updateSong(songId, payload);
    }

    deleteSongById(songId: number): AxiosPromise<void> {
        return bookingSongApi.deleteSongById(songId);
    }
}

export default new BookingSongService();
