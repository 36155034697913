





















import {Component, Vue} from 'vue-property-decorator'
import {LiveStream} from "@/model/song";
import boodkaFmDataService from "@/service/boodka-fm.data.service";
import youtubeService from "@/service/boodka-fm-youtube.service";
import {YoutubeVideoMeta} from "@/model/youtube-video-meta";
import StreamCard from "@/components/sections/StreamCard.vue";
import moment from "moment";
import {LAST_STREAMS_COUNT, LOCAL_DATE_FORMAT} from "@/constants/boodka-fm.constant";


@Component({
  components: {StreamCard}
})
export default class LatestStreamSection extends Vue {

  streams: LiveStream[] = [];
  streamsMap: Map<string, YoutubeVideoMeta> = new Map<string, YoutubeVideoMeta>()
  loading = false;
  loadedStreams = 0;


  loadStreams(): void {
    this.streams = boodkaFmDataService.getStreams(LAST_STREAMS_COUNT);
    this.loading = true;
    this.streams.forEach(s => {
          this.fetchVideoMeta(s.videoId, s.date);
        }
    )
  }

  fetchVideoMeta(id: string, date: string): void {
    youtubeService.getYoutubeVideo(id).then(resp => {
      resp.date = moment(date).format(LOCAL_DATE_FORMAT);
      this.streamsMap.set(id, resp);
    }).finally(() => {
      this.loadedStreams++;
      if (this.loadedStreams === this.streams.length) {
        this.loading = false;
      }
    })

  }

  getVideoMeta(id: string): YoutubeVideoMeta | undefined {
    return this.streamsMap.get(id);
  }


  mounted() {
    this.loadStreams();
  }
}
