
















import {Component, Vue} from 'vue-property-decorator'
import boodkaFmDataService from "@/service/boodka-fm.data.service";
import {LiveStream, Song, SongEntry} from "@/model/song";


@Component({})
export default class StreamsSummary extends Vue {

  liveStreams: LiveStream[] = [];
  songs: Song[] = [];
  songEntries: SongEntry[] = [];

  loadLiveStreams(): void {
    this.liveStreams = boodkaFmDataService.getStreams();
  }

  loadSongs(): void {
    this.songs = boodkaFmDataService.getSongs();
  }

  loadSongEntries(): void {
    this.songEntries = boodkaFmDataService.getSongsEntries();
  }

  mounted() {
    this.loadSongs();
    this.loadLiveStreams();
    this.loadSongEntries();
  }
}
