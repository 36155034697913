import {Configuration} from '@/api/configuration';
import {AuthenticateApi} from '@/api/service/authenticate-api';
import globalAxios, {AxiosInstance} from 'axios';
import store from '@/store';
import {UsersApi} from "@/api/service/users-api";
import {AccountApi} from "@/api/service/account-api";
import {ACCESS_TOKEN_KEY} from "@/store/constants/users";
import {BookingSongApi} from "@/api/service/booking-song-api";
import {BookingSongAudioApi} from "@/api/service/booking-song-audio-api";


class ApiInstance {

    axiosInstance: AxiosInstance;
    authenticateApi: AuthenticateApi;
    accountApi: AccountApi;
    usersApi: UsersApi;
    bookingSongApi: BookingSongApi;
    bookingSongAudioApi: BookingSongAudioApi;

    constructor() {
        this.axiosInstance = globalAxios;

        this.axiosInstance.interceptors.request.use(requestConfig => {
            requestConfig.headers.authorization = `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_KEY)}`
            return requestConfig
        }, error => {
            return Promise.reject(error)
        });

        this.axiosInstance.interceptors.response.use((response) => response, (error) => {
            if (error.response.status === 401 || error.response.status === 403) {
                store.commit('logout');
            }
            throw error
        });

        let baseURL

        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            baseURL = '/api'
        } else {
            baseURL = 'https://boodkafm-backend.herokuapp.com/api'
        }

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        this.authenticateApi = new AuthenticateApi(new Configuration(), baseURL, axiosInstance);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        this.usersApi = new UsersApi(new Configuration(), baseURL, axiosInstance);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        this.accountApi = new AccountApi(new Configuration(), baseURL, axiosInstance);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        this.bookingSongApi = new BookingSongApi(new Configuration(), baseURL, axiosInstance);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        this.bookingSongAudioApi = new BookingSongAudioApi(new Configuration(), baseURL, axiosInstance);
    }
}

export const {
    axiosInstance,
    authenticateApi,
    usersApi,
    accountApi,
    bookingSongAudioApi,
    bookingSongApi
} = new ApiInstance();
