import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);

const messages = {
    ru: {
        section: {
            slider: {
                title: "boodka",
                subtitle: "Привет всем на будкеФм",
                search: "Поиск в каталоге по артистам и названиям песен...",
                scrollDown: "Прокрути вниз"
            },
            statistics: {
                title: "Статистика",
                streamsCount: "Всего стримов",
                totalSongs: "Исполнено песен",
                totalArtists: "Кол-во артистов",
                uniqueSongs: "Уникальных песен",


            },
            boodkaFm: {
                title: "Интересные факты"
            },
            lastStream: {
                title: "Последние стримы",
                card: {
                    date: "Дата стрима",
                    toStream: "Перейти к видео"
                }
            },
            catalogue: {
                title: "Каталог стримов и песен"
            }
        },
        button: {
            subscribeToChannel: "Подписаться на канал",
            donate: "Поддержать",
            toCatalogue: "Перейти к каталогу",
            login: "Вход"
        },
        login: {
            title: "DASHBOARD",
            username: "Имя пользователя",
            password: "Пароль",
            errors: {invalidLogin: "Возникла ошибка"}
        }
    }
};

export default new VueI18n({
    locale: 'ru',
    messages
})
