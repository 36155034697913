















import {Component, Vue} from 'vue-property-decorator'
import boodkaFmDataService from "@/service/boodka-fm.data.service";
import {Alert} from "@/model/alert";
import googleDriveService from "@/service/google-drive.service";
import moment from "moment";

@Component({})
export default class AlertSection extends Vue {
  alerts: Array<Alert> = [];

  fetchExtraData(): void {
    googleDriveService.readData().then(response => {
      response.loadInfo().then(() => {
        const sheet = response.sheetsByIndex[0]
        sheet.getRows()
            .then((r: any) => {
              r.filter(s => moment(new Date()).isSameOrBefore(moment(s.date, 'DD.MM.YYYY'), 'day'))
                  .forEach(alert => {
                    this.alerts.push(
                        {
                          date: alert.date,
                          time: alert.time,
                          content: alert.content,
                          type: alert.type,
                          maxWidth: alert.maxWidth,
                          dismissible: !!alert.dismissible
                        }
                    )
                  });
            })
      })
    })
  }

  mounted() {
    this.fetchExtraData();
  }
}
