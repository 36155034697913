











import {Component, Vue} from 'vue-property-decorator';
import Songs from "@/components/Songs.vue";
import Slider from "@/components/sections/Slider.vue";
import LatestStreamSection from "@/components/sections/LastStreamSection.vue";
import LastStreamSection from "@/components/sections/LastStreamSection.vue";
import AboutSection from "@/components/sections/AboutSection.vue";
import BoodksFmSection from "@/components/sections/BoodksFmSection.vue";
import StatisticsSection from "@/components/sections/StatisticsSection.vue";
import CatalogueSection from "@/components/sections/CatalogueSection.vue";
import Footer from "@/views/Footer.vue";
import AlertSection from "@/components/sections/AlertSection.vue";

@Component({
  components: {
    AlertSection,
    Footer,
    CatalogueSection,
    StatisticsSection,
    LastStreamSection,
    AboutSection,
    LatestStreamSection,
    Slider,
    Songs
  }
})
export default class Home extends Vue {

}
