






















































import {Component, Prop, Vue} from 'vue-property-decorator'
import {Song, SongEntry} from "@/model/song";
import moment from "moment";
import {FOOTER_PROPS_5_10_ALL} from "@/constants/footer-props";


export const YOUTUBE_BASE = 'https://www.youtube.com/embed/';
export const YOUTUBE_TIME_CODE_ATTR = '?start=';


@Component({})
export default class SongDetails extends Vue {
  @Prop() song!: Song;

  footerProps = FOOTER_PROPS_5_10_ALL;
  video = '';

  headers = [
    {text: 'Date', value: 'date',},
  ]

  formatDateToLocal(isoDate: string): string {
    const date = moment(isoDate)
    return date.isValid() ? date.format('DD MMM yyyy') : isoDate
  }

  selectVideo(entry: SongEntry): void {

    if (!entry || !this.song) return;

    const tag = entry.video_id;
    const time = entry.start_time;

    if (tag && (time || time === 0)) {
      this.video = this.getUrl(tag, time);
    }

  }

  getUrl(videoTag: string, timeCode: number): string {
    return YOUTUBE_BASE + videoTag + YOUTUBE_TIME_CODE_ATTR + timeCode + '&autoplay=1;';
  }
}
