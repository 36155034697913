































import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator'
import {SingerType, SongBooking} from "@/model/song";
import boodkaFmDataService from "@/service/boodka-fm.data.service";
import SongDetails from "@/components/SongDetails.vue";
import StreamsSummary from "@/components/StreamsSummary.vue";
import {FOOTER_PROPS_DEFAULT} from "@/constants/footer-props";
import DashboardBookingListItem from "@/components/DashboardBookingListItem.vue";
import authService from "@/service/auth.service";
import bookingsongaudioService from "@/service/bookingsongaudio.service";
import {Audio, BookingSong} from "@/api";
import bookingsongService from "@/service/bookingsong.service";

@Component({
  components: {}
})
export default class DashboardAudioList extends Vue {
  @Prop()
  audios!: Audio[];

  @Prop()
  bookingSong!: BookingSong;

  get availableAudios(): Audio[] {
    return this.audios;
  }

  getGoogleDriveUrl(tag: string) {
    return "https://docs.google.com/uc?export=open&id=" + tag;
  }

  handleClick(song: BookingSong, audio: Audio): void {
    bookingsongService.getSongByBookingId(song.bookingId!).then(resp => {
      this.bookingSong = resp.data;
      this.link(this.bookingSong.id!, audio);
    }).catch(() => {
      bookingsongService.createSong(song).then((resp) => {
        this.bookingSong = resp.data;
        this.link(this.bookingSong.id!, audio);
      })
    })
  }

  link(songId: number, audio: Audio): void {
    bookingsongaudioService.updateAudio(songId, audio.id!, audio)
        .then(() => {
          this.updateList();
        })
  }

  @Emit('update-list')
  updateList(): boolean {
    this.bookingSong = {};
    return true;
  }

}
