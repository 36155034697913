






import {Component, Vue} from 'vue-property-decorator';
import Booking from "@/components/Booking.vue";
import DashboardBookingList from "@/components/DashboardBookingList.vue";

@Component({
  components: {DashboardBookingList}
})
export default class Dashboard extends Vue {

}
